import React from "react";
import { FaArrowRight, FaCheck } from "react-icons/fa";

interface StepperProps {
    steps: string[];
    activeStep: number;
    onChangeStep: (step: number) => void;
    onNextStep: () => void;
    onPrevStep: () => void;
    onCancel: () => void;
    onFinishStep: (e: any) => void;
    isLastStep: boolean;
    content: React.ReactNode;
    prevBtnText?:string;
    nextBtnText?:string;
    disabled?:boolean;
}

const Stepper: React.FC<StepperProps> = ({
    steps,
    activeStep,
    onChangeStep,
    onNextStep,
    onPrevStep,
    onCancel,
    onFinishStep,
    isLastStep,
    content,
    prevBtnText,
    nextBtnText,
    disabled,
}) => {
    return (
        <div className='flex flex-col items-center space-y-4 '>
            <div className='flex justify-between items-center w-full space-x-[5px]'>
                {steps.map((step, index) => (
                    <React.Fragment key={index}>
                        {index !== 0 && (
                            <>
                                <div
                                    className={`border-t border-[1.5px] flex-1 h-0 ${index <= activeStep ? "text-primary-400" : "text-neutral-25"
                                        }`}
                                ></div>
                                <div className='relative left-[-6px]'>
                                    <FaArrowRight
                                        className={`${index <= activeStep
                                            ? "text-primary-400"
                                            : "text-neutral-25"
                                            }`}
                                    />
                                </div>
                            </>
                        )}
                        <div className='flex items-center'>
                            <div
                                className={
                                    "cursor-pointer text-neutral-25 px-[1px] py-2 rounded flex"
                                }
                                onClick={() => onChangeStep(index)}
                            >
                                <div
                                    className={`flex items-center justify-center w-[24px] h-[24px] rounded-full mr-2 ${index <= activeStep
                                        ? "border-primary-600 border-[1px]"
                                        : "border-darkcolor-600"
                                        }`}
                                >
                                    <div
                                        className={`flex justify-center items-center w-[16px] h-[16px]  rounded-full mr-2 font-Poppins text-[12px] ${index <= activeStep
                                            ? "bg-primary-600"
                                            : "bg-darkcolor-600"
                                            }`}
                                        style={{ margin: "0 auto" }}
                                    >
                                        {index + 1 <= activeStep ? (
                                            <FaCheck className={"text-neutral-25"} />
                                        ) : (
                                            index + 1
                                        )}
                                    </div>
                                </div>
                                <div className='text-[14px]'>{step}</div>
                            </div>
                        </div>
                    </React.Fragment>
                ))}
            </div>

            {content}

            <div className='mt-8 w-full flex justify-between'>
                <button
                    className='px-[16px] py-[10px] border-[1px] rounded-[8px] flex justify-center items-center font-bold text-[14px] text-neutral-25 focus:outline-none hover:bg-darkcolor-600'
                    onClick={onCancel}
                >
                    Cancel
                </button>

                <div className='flex space-x-4'>
                    {activeStep !== 0 && (
                        <button
                            className='px-[16px] py-[10px] border-[1px] rounded-[8px] flex justify-center items-center font-bold text-[14px] text-neutral-25 focus:outline-none hover:bg-darkcolor-600'
                            onClick={onPrevStep}
                            disabled={activeStep === 0}
                        >
                          {prevBtnText ? prevBtnText : "Previous"}  
                        </button>
                    )}

                    <button
                        className={`px-[16px] py-[10px] border-[1px] border-primary-600 rounded-[8px] flex justify-center items-center font-bold text-[14px] text-neutral-25 bg-primary-600 focus:outline-none hover:bg-primary-700`}
                        onClick={isLastStep ? onFinishStep : onNextStep}
                        disabled={disabled}
                    >
                        {isLastStep ? "Save" : nextBtnText ? nextBtnText : "Next"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Stepper;
