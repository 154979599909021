const ApiConstant: any = {
  getProvisionDeviceSummary: "/ProvisionDevice/GetProvisionDeviceSummary",
  getProvisionSites: "/ProvisionDevice/GetProvisionSites",
  getProvisionPump: "/ProvisionDevice/GetProvisionPump",
  getProvisionTank: "/ProvisionDevice/GetProvisionTank",
  getDecviceStatus: "/Device/GetDecviceStatus",
  addProvisionDevice: "/ProvisionDevice/AddProvisionDevice",
  getDevices: "/ProvisionDevice/GetDevices",
  getToken: "/Account/AuthToken",
  getRefreshToken: "/Account/AuthRefreshToken",
  getUser: "/Groups/GetUsersByGroupId",
};

export default ApiConstant;
