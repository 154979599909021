import instance from "../api";
import ApiConstant from "../../constants/api.constants";
import Helpers from "../../utilities/helpers";

const getProvisionDeviceSummary = async (data: any) => {
  try {
    const response = await instance.post(
      ApiConstant.getProvisionDeviceSummary,
      data
    );
    return response.data;
  } catch (error) {
    Helpers.ConsoleLogError(error);
  }
};

const addProvisionDeviceData = async (data: any) => {
  try {
    const response = await instance.post(ApiConstant.addProvisionDevice, data);
    return response.data;
  } catch (error) {
    Helpers.ConsoleLogError(error);
  }
};

const getDeviceSites = async (data:any) => {
  try {
    const params = {
      PageSize: data.PageSize,
      PageNumber: data.PageNumber,
      ...(data.SortBy ? { SortBy: data.SortBy } : {}),
      ...(data.SortOrder ? { SortOrder: data.SortOrder } : {}),
      ...(data.SearchText ? { SearchText: data.SearchText } : {}),
    };
    const response = await instance.get(ApiConstant.getProvisionSites,{params});
    return response.data;
  } catch (error) {
    Helpers.ConsoleLogError(error);
  }
};

const getDevicePumps = async (data:any) => {
  try {
    const params = {
      TankId: data.TankId,
      PageSize: data.PageSize,
      PageNumber: data.PageNumber,      
      ...(data.SearchText ? { SearchText: data.SearchText } : {}),
    };
    const response = await instance.get(ApiConstant.getProvisionPump,{params});
    return response.data;
  } catch (error) {
    Helpers.ConsoleLogError(error);
  }
};

const getDeviceTanks = async (data:any) => {
  try {
    const params = {
      SiteId: data.SiteId,
      PageSize: data.PageSize,
      PageNumber: data.PageNumber,
      ...(data.SortBy ? { SortBy: data.SortBy } : {}),
      ...(data.SortOrder ? { SortOrder: data.SortOrder } : {}),
      ...(data.SearchText ? { SearchText: data.SearchText } : {}),
    };
    const response = await instance.get(ApiConstant.getProvisionTank,{params});
    return response.data;
  } catch (error) {
    Helpers.ConsoleLogError(error);
  }
};

const getDecviceStatusCheck = async (data: any) => {
  try {
    const params = {
      deviceId: data,
    };
    const response = await instance.get(ApiConstant.getDecviceStatus, {
      params,
    });
    return response.data;
  } catch (error) {
    Helpers.ConsoleLogError(error);
  }
};

const getDevices = async (data: any) => {
  try {
    const params = {
      skip: data.skip,
      limit: data.limit,
    };
    const response = await instance.get(ApiConstant.getDevices, { params });
    return response.data;
  } catch (error) {
    Helpers.ConsoleLogError(error);
  }
};

const ProvisionDeviceService = {
  getProvisionDeviceSummary,
  getDeviceSites,
  getDeviceTanks,
  getDevicePumps,
  getDecviceStatusCheck,
  addProvisionDeviceData,
  getDevices,
};

export default ProvisionDeviceService;
